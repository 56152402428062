import * as CONSTANT from '../../constants';
import {
  DELIVERY,
  DELIVERY_DESTINATION_ID,
  DELIVERY_ORDER_TYPE,
  IN_STORE,
  IN_STORE_DESTINATION_ID,
  KIDS_COMBO_ITEM,
  PICK_UP_METHOD_IN_STORE,
  PICK_UP_ORDER_TYPE,
  TRY_TO_COMBO_ITEM,
  FULL_SIZE,
} from '../../constants';

export const getDestination = (orderType: string) => {
  const destination = {
    [DELIVERY_ORDER_TYPE]: {
      destination_id: DELIVERY_DESTINATION_ID,
      order_type: DELIVERY,
    },
    [PICK_UP_METHOD_IN_STORE]: {
      destination_id: IN_STORE_DESTINATION_ID,
      order_type: IN_STORE,
    },
  };
  return destination[orderType];
};

export const getOrderType = (destinationId: number) => {
  const destination = {
    [DELIVERY_DESTINATION_ID]: DELIVERY_ORDER_TYPE,
    [IN_STORE_DESTINATION_ID]: PICK_UP_ORDER_TYPE,
  };
  return destination[destinationId];
};

const getPayments = (paymentCards, tipAmount, orderType) => {
  const eachCardAmount =
    tipAmount > 0 && orderType === DELIVERY_ORDER_TYPE
      ? tipAmount / paymentCards?.length
      : 0;
  return paymentCards?.map((payment) => {
    return {
      id: payment.id,
      amount: payment?.amount?.toFixed(2),
    };
  });
};

const getGiftCards = (giftCards) => {
  return giftCards?.map((payment) => {
    return {
      id: payment.id,
      amount: payment?.amount?.toFixed(2),
    };
  });
};
export const placeOrderPayload = (
  operator,
  authInfo,
  storeLocation,
  cart,
  checkout,
  customer,
  deliveryTipType,
  tipAmount,
  isExternalPayment = false,
  externalPaymentId = [],
  houseAccountId = '',
  totalAmount = 0,
  selectedTaxExemptAccountId = null,
  isTaxExampt = false,
  sentPaymentRequestEmail = [],
  sentPaymentRequestPhone = [],
) => {
  const uniqueEmails = sentPaymentRequestEmail.filter((email, index, self) => self.indexOf(email) === index);
  const uniquePhones = sentPaymentRequestPhone.filter((phone, index, self) => self.indexOf(phone) === index);
  const destination: any = getDestination(
    cart.orderDetails?.PickupSlotDetails.pickupMethod,
  );
  const orderDetails: any = cart.orderDetails;
  const payload: any = {
    customer_id: authInfo.id || authInfo.visitor_id,
    customer: {
      id: authInfo.id || authInfo.visitor_id,
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      phone: customer.phone,
      vehicle: {
        vehicle_information_id: orderDetails?.vehicleDetails?.id || null,
        make: orderDetails?.vehicleDetails?.make || null,
        model: orderDetails?.vehicleDetails?.model || null,
        color: orderDetails?.vehicleDetails?.color || null,
        is_saved: 1,
      },
      address: {
        address_id: orderDetails?.delivery_address?.id || null,
        address_name: orderDetails?.delivery_address?.address_name || null,
        full_address: null,
        street_name: orderDetails?.delivery_address?.street_name || null,
        zipcode: orderDetails?.delivery_address?.zipcode || null,
        city: orderDetails?.delivery_address?.city || null,
        state: orderDetails?.delivery_address?.state || null,
        apartment_no: orderDetails?.delivery_address?.apartment_no || null,
        is_saved: 1,
      },
    },
    location_id: storeLocation.id,
    destination_id: destination.destination_id,
    payment_methods: checkout?.textToPayToggle ? [] : checkout?.payInStoreToggle ? [] : isExternalPayment
      ? []
      : getPayments(
          checkout.paymentMethods,
          tipAmount,
          cart.orderDetails?.PickupSlotDetails.pickupMethod,
        ),
    gift_cards: checkout?.textToPayToggle ? [] : checkout?.payInStoreToggle ? [] : isExternalPayment ? [] : getGiftCards(checkout.giftCard),
    digital_payments: checkout?.textToPayToggle ? [] : checkout?.payInStoreToggle ? [] : isExternalPayment
      ? houseAccountId
        ? []
        : externalPaymentId
      : [],
    pay_in_store: checkout?.payInStoreToggle,
    send_payment_request: checkout?.textToPayToggle || false,
    phone_list: checkout?.textToPayToggle? uniquePhones : [],
    email_list: checkout?.textToPayToggle? uniqueEmails : [],
    // payment_methods: checkout?.payInStoreToggle ? [] : isExternalPayment
    // ? []
    // : getPayments(
    //     checkout.paymentMethods,
    //     tipAmount,
    //     cart.orderDetails?.PickupSlotDetails.pickupMethod
    //   ),
    // gift_cards: checkout?.payInStoreToggle ? [] : isExternalPayment ? [] : getGiftCards(checkout.giftCard),
    // digital_payments: checkout?.payInStoreToggle ? [] : isExternalPayment ? externalPaymentId : [],
    // pay_in_store: checkout?.payInStoreToggle,
    status: 'open',
    source: 'operator',
    cc_user_id: operator?.id,
    cc_username: operator?.name,
    slot: orderDetails.PickupSlotDetails?.isAsapOrder
      ? 'ASAP'
      : orderDetails.PickupSlotDetails?.time,
    business_date: orderDetails.PickupSlotDetails?.date,
    mins: orderDetails.PickupSlotDetails?.isAsapOrder
      ? orderDetails.PickupSlotDetails?.asapMinutes.toString()
      : '0',
    delivery_tip: deliveryTipType?.tip ,
    delivery_tip_format: deliveryTipType?.type || null,
  };
  if (houseAccountId && isExternalPayment)
    payload.house_accounts = [{ id: houseAccountId, amount: totalAmount }];
  if (isTaxExampt)
    payload.tax_exempt = {
      id: selectedTaxExemptAccountId,
    };
  return payload;
};

export const timeSlotPayload = (cart: any) => {
  let items = [];
  cart.items?.map((item) => {
    if (item.is_box_lunch || item?.is_package) {
      if (item.is_choose_for_me) {
        item.choose_modifiers.map((modifier) => {
          items.push({
            category_id: modifier.category_id,
            make_time_minutes: modifier.make_time_minutes,
            quantity: item.quantity,
            size: modifier?.item_size,
          });
        });
      } else {
        item.modifiers.map((modifier) => {
          if (modifier.modifier_type != CONSTANT.COMPLIMENTARY_MODIFIER ||
            modifier.modifier_type != CONSTANT.REQUIRED_MODIFIERS) {
            if (modifier.make_time_minutes) {
              items.push({
                category_id: modifier.category_id,
                make_time_minutes: modifier.make_time_minutes,
                quantity: item.quantity,
                size: item?.item_size === FULL_SIZE.upper_case ? 2 : 1,
              });
            }
          }
        });
      }
    } else {
      if (item.make_time_minutes) {
        items.push({
          category_id: item.category_id,
          make_time_minutes: item.make_time_minutes,
          quantity: item.quantity,
          size: item?.item_size === FULL_SIZE.upper_case ? 2 : 1,
        });
      }
    }
  });
  const finalPayload = {
    items: items,
    delivery: cart.orderType == DELIVERY_ORDER_TYPE ? 1 : 0,
  };
  return cart.orderType == DELIVERY_ORDER_TYPE
    ? {
        ...finalPayload,
        dropoff_address: {
          ...cart.orderDetails?.delivery_address,
          zip_code: cart.orderDetails?.delivery_address?.zipcode,
        },
      }
    : finalPayload;
};

export const getPlaceOrderModalClass = (orderType: string) => {
  return orderType == DELIVERY_ORDER_TYPE
    ? 'theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal full-modal-ipad'
    : 'chose-item-modal theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8 full-modal-ipad';
};

export const getPlaceOrderModalSize = (orderType: string) => {
  return orderType == DELIVERY_ORDER_TYPE ? 'sm' : 'xl';
};
