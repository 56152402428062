import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import CustomModal from 'src/components/customModal/CustomModal';
import ItemOrderModal from 'src/components/ItemOrderModal/ItemOrderModal';
import ItemPickupDeliveryModal from 'src/components/ItemOrderModal/ItemPickupDeliveryModal';
import {
  getUser,
  isCartItemsUnavailable,
  isCartModifiersUnavailable,
  persistZero,
  roundNumber,
} from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useSyncCart from 'src/hooks/useSyncCart';
import useExitEditOrder from 'src/react-query-hooks/useExistEditOrder';
import { resetCheckout, updateTotalAmount } from 'src/redux/slices/checkoutSlice';

import {
  DELIVERY_FEE_PERCENT,
  DELIVERY_ORDER_TYPE,
  MINIMUM_SUBTOTAL,
  OFFER_TYPE_FIXED_DISCOUNT,
  PICK_UP_ORDER_TYPE,
  SERVICE_FEE,
  STEP3,
} from '../../../constants';
import { roundToTwo } from '../../../priceCalculation/helper';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';
import GuestCheckoutModal from '../../GuestCheckout/GuestCheckoutModal';

import styles from './billing.module.scss';
import SendQuoteModal from './SendQuoteModal';
import useSaveDraft from 'src/react-query-hooks/useDraft';
import { saveDraftMapper } from 'src/helper/utils';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
import { onStepClick } from 'src/redux/slices/wizardSlice';
import Button from 'src/components/Button/Button';
import FormField from 'src/components/FormFields/FormField';

/*
 * TODO: use context to get list of items added in cart and calculate the bill
 */
interface IBillingProps {
  handleShowPickupMethodModal?: boolean;
  handleClosePickupMethodModal?: boolean;
  handleShowDeliveryMethodModal?: boolean;
  handleCloseDeliveryMethodModal?: boolean;
  isOrderTypeSelected?: boolean;
  orderType?: string;
  cartItems: any;
  isOrdered?: boolean;
  authInfo?: any;
  orderTippingAmount?: number;
  isCheckoutBilling?: boolean;
  order?: any;
  tax?: number;
  titlespace?: string;
  setTotal?: (number) => void;
  setSubTotal?: (subTotal: number) => void; // Used for offer redemption calculation
  setDiscountedSubTotal?: (value: number) => void;
  handleTax?: boolean;
  offerDiscount?: number;
  disableCheckout?: boolean;
  offerDiscountType?: string;
  className?: string;
  tip?: any;
  selectedTaxExempt?: string;
  isTaxExempt?: boolean;
  isTaxExempted?: boolean;
  isCart?: boolean;
  deesignAsPermodal?: boolean;
  ref?: any;
  defaultGiftCard?: any;
  housePaymentMethod?: boolean;
}

const Billing = forwardRef((props: IBillingProps, ref) => {
  const {
    handleShowPickupMethodModal,
    handleShowDeliveryMethodModal,
    isOrderTypeSelected,
    orderType,
    cartItems = [],
    isOrdered,
    orderTippingAmount,
    isCheckoutBilling,
    tax,
    order,
    setTotal,
    setSubTotal,
    setDiscountedSubTotal,
    offerDiscount,
    disableCheckout,
    handleTax = true,
    className,
    offerDiscountType,
    selectedTaxExempt = 'true',
    isTaxExempt = false,
    isTaxExempted = false,
    isCart = false,
    deesignAsPermodal = false,
    defaultGiftCard,
    housePaymentMethod,
  } = props;

  useImperativeHandle(
    ref,
    () => {
      return {
        showOrderTypeModal() {
          showOrderTypeModal();
        },
      };
    },
    [],
  );

  const [openPickupMethodModal, setOpenPickupMethodModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [draftName, setDraftName] = useState('');
  const [openDeliveryMethodModal, setOpenDeliveryMethodModal] = useState(false);
  const [changeOrderType, setChangeOrderType] = useState(false);
  const [sendQuoteModal, setSendQuoteModal] = useState(false);
  const locationInfo = useAppSelector((state) => state.location);
  const [displaySubTotal, setDisplaySubTotal] = useState<number>(0);
  const [displayDiscount, setDisplayDiscount] = useState<number>(0);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [showContactInfo, setShowContactInfo] = useState<boolean>(false);
  const [quoteSentModal, setQuoteSentModal] = useState(false);
  const [applyCzDollar, setApplyCZDollar] = useState(false);
  const [sentEmailTo, setSentEmailTo] = useState([]);
  const handleShowContactForm = () => setShowContactForm(true);
  const handleCloseContactForm = () => setShowContactForm(false);
  const cartTax = useAppSelector((state) => state.cart.tax);
  const isEditOrder = useAppSelector((state) => state.cart.is_edit_order);
  const orderId = useAppSelector((state) => state.cart.orderId);
  const location_id = locationInfo.selectedStore?.id;
  const { syncCart, loading: syncingCart } = useSyncCart();

  const { redirect } = useRedirectToSpecificStep();

  const redirectToSpecificStep = (id) => {
    dispatch(onStepClick({ stepId: id }));
    redirect(id);
  };
  
  const { mutateAsync: saveAsDraft, isLoading } = useSaveDraft();
  const user = getUser();
  
  const { user: authInfo } = useAppSelector((state) => state.user);
  const userInfo = authInfo.id;
  const location = useAppSelector((state) => state.location);
  const wizard = useAppSelector((state) => state.wizard);
  const checkout = useAppSelector((state) => state.checkout);
  const { recievingUser } = useAppSelector((state) => state.user);

  const specificFlowState = useAppSelector((state) => state.specificFlowStates);
  const { currentStep, steps } = useAppSelector((state) => state.wizard);
  const cart = useAppSelector((state) => state.cart);
  const savedOrderType = orderType;
  useEffect(() => {
    calculateSubTotal();
  }, [cartItems, offerDiscount]);

  useEffect(() => {
    if (handleShowPickupMethodModal) {
      setOpenPickupMethodModal(true);
    }
    if (handleShowDeliveryMethodModal) {
      setOpenDeliveryMethodModal(true);
    }
  }, [handleShowPickupMethodModal, handleShowDeliveryMethodModal]);
  function calculateTax(): number {
    if ((handleTax && !isTaxExempt) || !selectedTaxExempt) {
      return cartTax;
    } else {
      return 0;
    }
  }
  
  function calculateTaxincludeTax(): number {
    if ((!isTaxExempt) || !selectedTaxExempt) {
      return cartTax;
    } else {
      return 0;
    }
  }
  const { mutateAsync, isLoading: isExitOrderLoading } = useExitEditOrder();

  const handleExitEditOrder = () => {
    return mutateAsync(
      { id: orderId },
      {
        onSuccess: (data) => {
          dispatch(resetCheckout());
          syncCart(location_id);
          Toast_Func({
            status: true,
            message: 'Closed edit order successfully',
          });
          redirectToSpecificStep(STEP3);
        },
        onError: (error) => {
          Toast_Func({
            status: false,
            message: 'An error occurred',
          });
        },
      },
    );
  };
  const toFixedNumber = (number: number): number => {
    return Number(roundNumber(number, 2));
  };

  const calculateDeliveryFee = () => {
    if (!cart.free_delivery) {
      return toFixedNumber(
        toFixedNumber(displaySubTotal - displayDiscount) *
          (DELIVERY_FEE_PERCENT / 100),
      );
    }
    return 0
  };

  const calculateOfferDiscount = (totalAmount: number) => {
    if (offerDiscountType === OFFER_TYPE_FIXED_DISCOUNT) {
      return offerDiscount;
    } else {
      const discount = (totalAmount * offerDiscount) / 100;
      return discount;
    }
  };

  function calculateSubTotal() {
    if (cartItems.length > 0) {
      let displayTotal = 0;
      cartItems.forEach((item: any) => {
        const price = item.gross_price ?? item.display_price;
        displayTotal += roundToTwo(price * item.quantity);
      });
      setSubTotal?.(displayTotal);
      setDisplaySubTotal(displayTotal);
      const discountByOffer = calculateOfferDiscount(displayTotal);
      setDisplaySubTotal(displayTotal);
      if (discountByOffer > displayTotal) {
        setDiscountedSubTotal?.(0);
        setDisplayDiscount(displayTotal);
      } else {
        setDisplayDiscount(discountByOffer);
        setDiscountedSubTotal?.(roundToTwo(displayTotal - discountByOffer));
      }
    } else {
      setSubTotal?.(0);
      setDisplaySubTotal(0);
      setDisplayDiscount(0);
    }
  }
  const dispatch = useAppDispatch();
  // const refetchLocation = async () => {
  //   console.log('refetchLocationbefpre', locationInfo?.selectedStore?.id);
  //   const response = await locationMenuService.getLocationDetails(
  //     locationInfo?.selectedStore?.id,
  //   );
  //   if (response) dispatch(setLocation(response?.data?.data));
  //   console.log('refetchLocationafter');
  // };

  // useEffect(() => {
  //   if (locationInfo?.selectedStore?.id) {
  //     refetchLocation();
  //   }
  // }, []);

  useEffect(() => {
    calculateTotal();
  }, [cartTax]);

  const onCheckout = async () => {
    if (
      displaySubTotal < MINIMUM_SUBTOTAL &&
      orderType === DELIVERY_ORDER_TYPE
    ) {
      Toast_Func({
        status: false,
        message: 'Subtotal should be greater then $' + MINIMUM_SUBTOTAL,
      });
      return;
    }
    showOrderTypeModal();
  };

  const calculateDiscountedTotal = () => {
    let grandTotal = orderTippingAmount
      ? displaySubTotal - displayDiscount + orderTippingAmount
      : displaySubTotal - displayDiscount;
    if (savedOrderType === DELIVERY_ORDER_TYPE && cartItems?.length) {
      grandTotal = grandTotal + calculateDeliveryFee() + SERVICE_FEE;
    }
    return grandTotal >= 0 ? grandTotal : 0;
  };

  function calculateTotal(): number {
    const total = locationInfo?.selectedStore?.tax_rate
      ? calculateDiscountedTotal() + calculateTax()
      : calculateDiscountedTotal();
    setTotal?.(Number(roundNumber(total, 2)));
    return total;
  }

  function calculateTotalIncludeTax(): number {
    const total = locationInfo?.selectedStore?.tax_rate
      ? calculateDiscountedTotal() + calculateTaxincludeTax()
      : calculateDiscountedTotal();
    setTotal?.(Number(roundNumber(total, 2)));
    return total;
  }

  const handleClosePickupMethodModal = () => {
    setOpenPickupMethodModal(false);
  };
  const handleCloseDeliveryMethodModal = () => {
    setOpenDeliveryMethodModal(false);
    setOpenPickupMethodModal(false);
  };

  const showOrderTypeModal = () => {
    // setHandleChangeOrderType(true)
    if (isOrderTypeSelected && savedOrderType == PICK_UP_ORDER_TYPE) {
      setOpenPickupMethodModal(true);
      setOpenDeliveryMethodModal(false);
    } else {
      setOpenDeliveryMethodModal(true);
      setOpenPickupMethodModal(false);
    }
  };

  const closeOrderTypeModal = () => {
    setChangeOrderType(false);
    showOrderTypeModal();
  };

  const closeChangeOrderTypeModal = () => {
    setChangeOrderType(false);
  };

  const showChangeOrderType = (changeType) => {
    setChangeOrderType(changeType);
  };

  const totalForPlacedOrder = () => {
    const grandTotal = orderTippingAmount
      ? order?.total_price + orderTippingAmount
      : order?.total_price;
    return roundToTwo(Number(grandTotal));
  };
  const closeSendQuoteModal = () => {
    setSendQuoteModal(false);
  };

  const closeQuoteSentModal = () => {
    setQuoteSentModal(false);
  };

  const handleCloseSendQuoteModal = () => {
    setSendQuoteModal(true);
  };
  const quoteProps = {
    tax: isOrdered ? `${roundNumber(tax, 2)}` : `${toFixedNumber(cartTax)}`,
    sub_total: isOrdered
      ? `${order?.gross_sales?.toFixed(2)}`
      : `${persistZero(toFixedNumber(displaySubTotal))}`,
    discount:
      order?.discount || displayDiscount > 0
        ? order?.discount || roundToTwo(displayDiscount)
        : null,
    tax_exemption:
      selectedTaxExempt && isTaxExempt
        ? isOrdered
          ? `${roundNumber(tax, 2)}`
          : `${toFixedNumber(cartTax)}`
        : null,
    tip: orderTippingAmount ? `${roundNumber(orderTippingAmount, 2)}` : null,
    delivery_fee:
      orderType == DELIVERY_ORDER_TYPE && cartItems?.length && !isOrdered
        ? `${calculateDeliveryFee()}`
        : orderType == DELIVERY_ORDER_TYPE && isOrdered
        ? order?.delivery_fee?.toFixed(2)
        : null,
    service_fee:
      orderType == DELIVERY_ORDER_TYPE && isOrdered
        ? order?.service_fee?.toFixed(2)
        : null,
    cz_dollar: applyCzDollar && defaultGiftCard?.balance > 0 ? 
        isOrdered
          ? 
          parseFloat(defaultGiftCard?.balance) >= parseFloat(persistZero(totalForPlacedOrder()) as string) ? 
            persistZero(totalForPlacedOrder()) :
            defaultGiftCard?.balance
        : parseFloat(defaultGiftCard?.balance) >= parseFloat(persistZero(roundNumber(calculateTotalIncludeTax(), 2)) as string)  ?
            persistZero(roundNumber(calculateTotalIncludeTax(), 2)) :
            defaultGiftCard?.balance
      : null,
    total_price: applyCzDollar && defaultGiftCard?.balance > 0 ? 
      isOrdered ? 
          parseFloat(persistZero(totalForPlacedOrder()) as string) >= parseFloat(defaultGiftCard?.balance) ? 
            persistZero(parseFloat(persistZero(totalForPlacedOrder()) as string) - parseFloat(defaultGiftCard?.balance)):
            0.0
        : parseFloat(persistZero(roundNumber(calculateTotalIncludeTax(), 2)) as string) >= parseFloat(defaultGiftCard?.balance) ?
            persistZero(parseFloat(persistZero(roundNumber(calculateTotalIncludeTax(), 2)) as string) - parseFloat(defaultGiftCard?.balance)) :
            0.0
    : isOrdered ? 
      `${persistZero(totalForPlacedOrder())}`
      : `${persistZero(roundNumber(calculateTotalIncludeTax(), 2))}`,
  };

  const taxShow = () => {
    if (handleTax) {
      return (
        <li className="d-flex justify-content-between mb-2">
          <div className="font-plex light-blue f-s14 f-w5">
            Tax{' '}
            {isTaxExempted ? (
              <span className=" clr-dark-red">(Tax Exempted)</span>
            ) : (
              ''
            )}{' '}
          </div>
          <span className="d-inline-block ms-3 f-s14 f-w5 light-blue font-plex">
            {isOrdered
              ? `$${roundNumber(tax, 2)}`
              : `$${toFixedNumber(cartTax)}`}
          </span>
        </li>
      );
    } else return null;
  };
  
  const saveDraftObject = saveDraftMapper({
    customer: null,
    receiver: recievingUser,
    location,
    cart,
    wizard,
    checkout,
    specificFlowState,
    user: userInfo,
  });

  const onDraftHandler = async (draftObject) => {
    const response = await saveAsDraft(draftObject);
    if (response.id) {
      Toast_Func({ status: true, message: 'A Draft has been saved' });
      setDraftModal(false);
      setDraftName('');
      if (!cart.is_edit_order) {
        redirect(STEP3);
        dispatch(onStepClick({ stepId: STEP3 }));
      }
      return;
    } else {
      Toast_Func({
        status: false,
        message: 'An error occurred while saving information as draft',
      });
    }
  };
  useEffect(() => {
    if (isOrdered) {
      dispatch(updateTotalAmount(persistZero(totalForPlacedOrder())));
    } else {
      dispatch(updateTotalAmount(persistZero(roundNumber(calculateTotal(), 2))))
    }
  }, [orderTippingAmount,locationInfo?.selectedStore?.tax_rate,displaySubTotal]);
  return (
    <>
      <div
        className={`${className} ${
          isCart && isEditOrder ? 'pb-5 mb-4' : 'pb-3'
        }`}
      >
        {currentStep === 4 || currentStep === 3 ? null : <h4
          className={`text-start h-22 ls-normal font-plex f-w5 f-s18 mb-1 light-blue`}
        >
          Bill Details
        </h4>}
        <ul className={`list-inline pt-3 ${currentStep === 4 || currentStep === 3 ? 'mb-0' : 'mb-3'} ${currentStep === 4 || currentStep === 3 ? 'px-4' : 'border-top'}`}>
          <li className="d-flex justify-content-between mb-2">
            <div className="font-plex light-blue f-s14 f-w5">Subtotal </div>
            <span
              className="d-inline-block ms-3 f-s14 f-w5 font-plex light-blue"
              id="display-sub-total-before-order-place"
            >
              {isOrdered
                ? `$${order?.gross_sales?.toFixed(2)}`
                : `$${persistZero(toFixedNumber(displaySubTotal))}`}
            </span>
          </li>
          {order?.discount || displayDiscount > 0 ? (
            <li className={`clr-dark-red d-flex justify-content-between mb-2`}>
              <div className="font-plex f-s14 f-w5">Discount </div>
              <div className="ms-3 f-w5 font-plex f-s14">{`$${
                order?.discount || roundToTwo(displayDiscount)
              }`}</div>
            </li>
          ) : null}
          {taxShow()}
          {selectedTaxExempt && isTaxExempt && (
            <li className="d-flex justify-content-between mb-2">
              <div className="font-plex f-s14 f-w5 clr-dark-red">
                Tax Exemption
              </div>
              <span className="d-inline-block ms-3 f-s14 f-w5 font-plex clr-dark-red">
                {isOrdered
                  ? ` - $${roundNumber(tax, 2)}`
                  : ` - $${toFixedNumber(cartTax)}`}
              </span>
            </li>
          )}
          {orderTippingAmount ? (
            <li className={`d-flex justify-content-between mb-2 light-blue`}>
              <div className="font-plex f-s14 f-w5">Tip </div>
              <span className="font-plex d-inline-block f-s14 f-w5 ms-3 light-blue">
                ${`${roundNumber(orderTippingAmount, 2)}`}
              </span>
            </li>
          ) : null}
          {orderType == DELIVERY_ORDER_TYPE &&
          cartItems?.length &&
          !isOrdered ? (
            <>
              <li className={`d-flex justify-content-between mb-2 ${cart.free_delivery && "clr-dark-red font-plex"}`}>
                <div className="font-Visby-cf f-s14 f-w5">Delivery Fee </div>

                <span className="d-inline-block ms-3  f-s14 f-w5 font-Visby-cf">
                  ${calculateDeliveryFee()}
                </span>
              </li>
              {/* <li className={`d-flex justify-content-between`}>
                <div className="font-Visby-cf f-s16 f-w5"> Service Fee </div>

                <span className="d-inline-block ms-3 f-s16 f-w5 font-Visby-cf">
                  ${SERVICE_FEE}
                </span>
              </li> */}
            </>
          ) : null}
          {orderType == DELIVERY_ORDER_TYPE && isOrdered ? (
            <>
              {order?.delivery_fee ? (
                <li className={`d-flex justify-content-between mb-2`}>
                  Delivery Fee{' '}
                  <span className="d-inline-block ms-3 font-Visby-cf">
                    ${order?.delivery_fee?.toFixed(2)}
                  </span>{' '}
                </li>
              ) : null}
              {order?.service_fee ? (
                <li>
                  Service Fee{' '}
                  <span className="d-inline-block ms-3 font-Visby-cf">
                    ${order?.service_fee?.toFixed(2)}
                  </span>{' '}
                </li>
              ) : null}
            </>
          ) : null}
          <hr className="custom_hr_sty mb-2"></hr>
          <li className={`d-flex justify-content-between`}>
            <div className="font-plex light-blue f-s14 f-w7"> Total</div>
            {isOrdered ? (
              <a
                className="d-inline-block ms-3 text-decoration-none clr-dark-grey font-Visby-cf f-s14 f-w8"
                href="javascript:void(0);"
              >
                {isOrdered
                  ? `$${persistZero(totalForPlacedOrder())}`
                  : `$${persistZero(roundNumber(calculateTotal(), 2))}`}
              </a>
            ) : (
              <span className="d-inline-block ms-3 font-plex light-blue f-s14 f-w7">
                {isOrdered
                  ? `$${persistZero(totalForPlacedOrder())}`
                  : `$${persistZero(roundNumber(calculateTotal(), 2))}`}
              </span>
            )}
          </li>
        </ul>

        {isCheckoutBilling && !isOrdered ? (
            <div className={`${styles.sendQuoteBt} mx-4 mt-2`}>
              <button
                type="button"
                className={`btn-large w-100 text-uppercase d-flex font-plex justify-content-center`}
                disabled={
                  cartItems.length <= 0 ||
                  isCartItemsUnavailable(cartItems) ||
                  isCartModifiersUnavailable(cartItems) ||
                  disableCheckout
                }
                onClick={handleCloseSendQuoteModal}
              >
                Send Quote
              </button>
            </div>
        ): null}

        {!isCheckoutBilling && !isOrdered ? (
          <div
            className={`${deesignAsPermodal && styles.checkout_btn_wrap} ${
              styles.sendQuoteBtn
            }`}
          >
            {deesignAsPermodal ? (
              <button
                type="button"
                className={`btn-large w-100 text-uppercase d-flex font-plex justify-content-between`}
                disabled={
                  cartItems.length <= 0 ||
                  isCartItemsUnavailable(cartItems) ||
                  isCartModifiersUnavailable(cartItems) ||
                  disableCheckout
                }
                onClick={onCheckout}
              >
                Checkout
                <span className="d-inline-block ms-3">
                  ${persistZero(roundNumber(calculateTotal(), 2))}
                </span>
              </button>
            ) : null }
            {isCart && isEditOrder ? (
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn-large w-100 text-uppercase mt-3 d-flex gap-2 justify-content-center align-items-center"
                  onClick={handleExitEditOrder}
                  disabled={isExitOrderLoading || syncingCart}
                >
                  Exit Edit Order
                  {(isExitOrderLoading || syncingCart) && <Spinner size="sm" />}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
      <ItemPickupDeliveryModal
        subTotal={toFixedNumber(displaySubTotal)}
        tax={toFixedNumber(calculateTax())}
        total={toFixedNumber(calculateTotal())}
        openPickupMethodModal={openPickupMethodModal}
        handleClosePickupMethodModal={handleClosePickupMethodModal}
        editPickupType={showChangeOrderType}
        editDeliveryType={showChangeOrderType}
        handleCloseDeliveryMethodModal={handleCloseDeliveryMethodModal}
        openDeliveryMethodModal={openDeliveryMethodModal}
        handleChangeOrderType={false}
      />

      <CustomModal
        showModal={changeOrderType}
        title={'Select Your Order Type'}
        closeModal={closeChangeOrderTypeModal}
        modalDialogClasses={'defaultModalwrapper'}
      >
        <ItemOrderModal
          handleShowDeliveryMethodModal={closeOrderTypeModal}
          handleShowPickupMethodModal={closeOrderTypeModal}
          closeOpenOrderModal={closeChangeOrderTypeModal}
          editChangeToDelivery={orderType == PICK_UP_ORDER_TYPE}
          editChangeToPickup={orderType == DELIVERY_ORDER_TYPE}
        />
      </CustomModal>

      <GuestCheckoutModal
        showContactForm={showContactForm}
        handleCloseContactForm={handleCloseContactForm}
        currentGuestUserInfo={user}
        showContactInfo={showContactInfo}
        setShowContactInfo={setShowContactInfo}
        handleSubmitAsGuest={showOrderTypeModal}
        userStatusData={user}
      />
      <CustomModal
        showModal={sendQuoteModal}
        setApplyCZDollar={setApplyCZDollar}
        title={'Send Quote'}
        closeModal={closeSendQuoteModal}
        modalDialogClasses={'defaultModalwrapper'}
        titletextAlign={'text-start'}
        crossBtnIcom={'light-blue-icon'}
      >
        <SendQuoteModal
          props={quoteProps}
          closeSendQuoteModal={closeSendQuoteModal}
          setQuoteSentModal={setQuoteSentModal}
          setSentEmailTo={setSentEmailTo}
          setDraftModal={setDraftModal}
          applyCzDollar={applyCzDollar}
          setApplyCZDollar={setApplyCZDollar}
          defaultGiftCard={defaultGiftCard}
          isHouseAccount={housePaymentMethod}
        />
      </CustomModal>
      <CustomModal
        showModal={quoteSentModal}
        title={'Quote Sent'}
        closeModal={closeQuoteSentModal}
        modalDialogClasses={'defaultModalwrapper'}
        titletextAlign={'text-start'}
        crossBtnIcom={'light-blue-icon'}
      >
        <div className="p-4">
          <p className="f-s20 f-w4 light-blue  mb-2">
            A quote has been emailed to{' '}
          </p>
          <ul className={`f-w4 light-blue f-s16 ${styles.emailListing}`}>
            {sentEmailTo?.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
        <hr className="custom_hr_sty" />
        <div className="d-flex gap-4 justify-content-end px-3 pb-3">
          <button
            onClick={closeQuoteSentModal}
            type="button"
            className="global-filled-btn clr-white"
          >
            Ok
          </button>
        </div>
      </CustomModal>
      
      <CustomModal
        showModal={draftModal}
        title={'Name your draft'}
        closeModal={() => setDraftModal(false)}
        modalDialogClasses={'defaultModalwrapper'}
      >
        <div className="border-bottom pt-2 pb-3 px-4">
          {/* <p className="f-s18 light-blue font-plex f-w4 mb-3">
                Please enter draft name
              </p> */}
          <div className="">
            <FormField
              name="draft_name"
              labelText="Draft Name"
              placeholder="Please enter draft name"
              type="text"
              onChange={(event) => setDraftName(event.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-3 px-4 py-3">
          <button
            className="cancel-filled-btn"
            type="button"
            onClick={() => setDraftModal(false)}
          >
            {' '}
            Cancel
          </button>
          <Button
            className="global-filled-btn"
            type="button"
            disabled={!draftName.length}
            onClick={() => {
              saveDraftObject.draft_name = draftName;
              onDraftHandler(saveDraftObject);
            }}
            loading={isLoading}
          >
            Save as Draft
          </Button>
        </div>
      </CustomModal>
      
      
    </>
  );
});

export default Billing;
